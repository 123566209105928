import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ToastController, ModalController, MenuController, LoadingController, AlertController, Platform, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-share-folder',
  templateUrl: './share-folder.page.html',
  styleUrls: ['./share-folder.page.scss'],
})
export class ShareFolderPage implements OnInit {

  public selected_user_ids = this.navParams.get('selected_user_ids');
  public folder_id = this.navParams.get('folder_id');

  selectedUsers: any = [];
  loading_spinner: boolean;
  page = 1;
  serach_keyword: any;
  user: any = [];
  loading: any;
  usersList: any = [];
  users_count: number = 0;
  msg_user_not_found: any = '';
  is_select_all:boolean = false;

  constructor(
    public router: Router,
    public loadingCtrl: LoadingController,
    public modalController: ModalController,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    public toastController: ToastController,
    private navParams: NavParams,
  ) {
    this.storage.get('user').then((user) => {
      if (user) {
        this.user = user;
      }
    });
    if(this.selected_user_ids.length > 0){
      this.selectedUsers = this.selected_user_ids;
    }else{
      this.selectedUsers = [];
    }
    
    console.log("folder_id :", this.folder_id);
    console.log("selectedUsers :",this.selectedUsers);
  }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.getUsers();
  }

  async closeModal(action) {
    await this.modalController.dismiss({ action: action });
  }

  getUsers() {
    this.storage.get('user').then((user) => {
      if (user) {
        this.loading_spinner = true;
        this.page = 1;
        let sendData: any = {
          page: this.page,
          token: user.token,
          serach_keyword: this.serach_keyword,
        }
        this.serviceForAllService.get_all_users(sendData).subscribe((result) => {
          this.loading_spinner = false;
          let res: any = [];
          res = result;
          this.usersList = res.users_list;

          let i=0;
          this.usersList.forEach((p) => {
            if(this.selectedUsers.indexOf(p.user_id) > -1){
              this.usersList[i].isChecked = true;
            }else{
              this.usersList[i].isChecked = false;
            }
            i++;
          });

          this.users_count = res.count;
          if (this.users_count == 0) {
            this.msg_user_not_found = "No user found";
          }

        }, (err) => {
          this.loading_spinner = false;
          console.log("error...", err);
          let msg = err.error.errormsg;
          this.storage.remove('user');
          this.presentToast(msg, ['/login']);
        });
      }
    });
  }
  getMoreUsers(event) {

    this.storage.get('user').then((user) => {
      if (user) {
        this.loading_spinner = true;
        this.page++;

        let sendData: any = {
          page: this.page,
          token: user.token,
          serach_keyword: this.serach_keyword,
        }
        this.serviceForAllService.get_all_users(sendData).subscribe((result) => {
          this.loading_spinner = false;
          let res: any = [];
          res = result;
          this.usersList = [...this.usersList, ...res.users_list];
          this.is_select_all = false;
          console.log("this.usersList count: " + this.usersList.length);
          let i=0;
          
          this.usersList.forEach((p) => {
            if(this.selectedUsers.indexOf(p.user_id) > -1){
              this.usersList[i].isChecked = true;
            }else{
              this.usersList[i].isChecked = false;
            }
            i++;
          });
          console.log("selectedUsers end:",this.selectedUsers);

          this.users_count = res.count;
          if (this.users_count == 0) {
            this.msg_user_not_found = "No more users";
          }
          event.target.complete();

        }, (err) => {
          event.target.complete();
          this.loading_spinner = false;
          console.log("error...", err);
          let msg = err.error.errormsg;
          this.storage.remove('user');
          this.presentToast(msg, ['/login']);
        });
      }
    });
  }
  serach_results() {
    this.getUsers();
  }

  selectAll(event:any){

    const isChecked = event.currentTarget.checked;
    let i=0;
    if(isChecked==true){

      this.is_select_all = true;
        this.usersList.forEach(user => {
          this.usersList[i].isChecked = true;
          this.selectedUsers.push(user.user_id);
          i++;
        });

    }else{
      this.is_select_all = false;

        this.usersList.forEach(user => {
          this.usersList[i].isChecked = false;
          this.selectedUsers = [];
          i++;
        });

    }
    console.log("selectedUsers all:",this.selectedUsers);
  }

  async presentToast(msg, page) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });

    toast.present().then(
      x => {
        console.log("Inside");
        this.router.navigate(page);
      }
    );
  }

  selectUser(user_id) {
    console.log("user_id :", user_id);
    let i = 0;
    let indx;
    indx = this.selectedUsers.indexOf(user_id);
    if (indx > -1) {
      this.selectedUsers.splice(indx, 1);
      console.log("selectedUsers =", this.selectedUsers);
      this.selectedUsers.forEach((s) => {
        if (s.user_id == user_id) {
          this.selectedUsers.splice(i, 1);
        }
        i++;
      });

    }
    else {
      this.selectedUsers.push(user_id);
    }
    console.log("selectedUsers =", this.selectedUsers);
  }

  shareFolder() {
    if(this.selectedUsers.length <= 0){
      this.presentToast("Please select users", '');
      return false;
    }
    
    console.log("yes");
    this.storage.get('user').then((user) => {
      if (user) {
        this.showLoader("");
        let sendData: any = {
          token: user.token,
          folder_id: this.folder_id,
          selected_user_ids: this.selectedUsers
        }
        this.serviceForAllService.share_folder(sendData).subscribe((result) => {
          this.loading.dismiss();
          let res: any = [];
          res = result;
          console.log("res :", res);
          this.presentToast(res.msg,'');
          this.modalController.dismiss({ action: 'ok' });

        }, (err) => {
          this.loading.dismiss();
          console.log("error...", err);
          let msg = err.error.msg;
          if (err.error.error_code = 'token_expired') {
            this.storage.remove('user');
            this.presentToast(msg, ['/login']);
          }
          this.presentToast(msg, '');

        });
      }
    });
  }
  
  gotoOtherUserProfile(uid) {

    this.modalController.dismiss({ action: 'cancel' });
    
    let userDetails: any = [];
    userDetails['uid'] = uid;
    let navigationExtras: NavigationExtras = {
      queryParams: userDetails
    };
    if (this.user.user_id == uid) {
      this.router.navigate(['/tabs/profile']);
    } else {
      this.router.navigate(['otheruser-profile'], navigationExtras);
    }
  }
  
  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({ message: msg, backdropDismiss: false });
    this.loading.present();
  }

}
