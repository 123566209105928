import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';
import { Router, NavigationExtras } from '@angular/router';
import { ToastController, ModalController, NavController, NavParams, MenuController, LoadingController, AlertController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonContent } from '@ionic/angular';

import {
  GoogleMap,
  GoogleMapsEvent,
  GoogleMapOptions,
  CameraPosition,
  MarkerOptions,
  Marker
} from '@ionic-native/google-maps';

declare var google;

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.page.html',
  styleUrls: ['./search-filter.page.scss'],
})
export class SearchFilterPage implements OnInit {

  @ViewChild('map', { static: false }) mapElement: ElementRef;
  @ViewChild('content', { static: false }) content: IonContent;

  map: GoogleMap;
  mapView: boolean = false;
  directionsDisplay = new google.maps.DirectionsRenderer;

  user: any;
  loading: any;
  filter_type: any;
  current_filters: any = '';
  loading_spinner: boolean;
  starBox: any = [];
  rate: number = 0;
  service = new google.maps.places.AutocompleteService();
  isSavedInput:boolean = false;

  AE_functional_areas: any = '';
  AE_highest_t_ac:any = '';
  AE_largest_pl:any = '';
  private_equity:any = '';
  business_experiencing:any = '';
  turn_around_restructuring:any = '';
  OI_ftc: any = '';
  OI_ftf: any = '';
  OI_ptf: any = '';

  isReady: boolean = false;

  selectedRole: string = '';
  industry_sector: any = [];
  business_subsector: any = [];
  consumer_subsector: any = [];
  energy_subsector: any = [];
  healthcare_subsector: any = [];
  industrials_subsector: any = [];
  technology_subsector: any = [];
  fund_size_min: number;
  fund_size_max: number;
  control_orientation: any;

  isBusinessSector: boolean = false;
  isConsumerSector: boolean = false;
  isEnergySector: boolean = false;
  isHealthcareSector: boolean = false;
  isIndustrialsSector: boolean = false;
  isTechnologySector: boolean = false;
  savedSearch:string = '';
  saved_executive:any = []; 
  saved_sponsor :any = [];
  isSaveFolder:boolean = false;
  folders:any = [];
  folder_id:number = 0;

  constructor(
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    public router: Router,
    private navParams: NavParams,
    public loadingCtrl: LoadingController,
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public menu: MenuController,
    public navCtrl: NavController,
    public toastController: ToastController,
    private geolocation: Geolocation,
    private zone: NgZone,
    private cdref: ChangeDetectorRef,
  ) {
    this.starBox = [1, 2, 3, 4, 5];
    this.rate = 0;

    setTimeout(() => {
      this.filter_type = this.navParams.get('filter_type');
      this.selectedRole = this.navParams.get('selectedRole');
    }, 200);

    this.storage.get('user').then((user) => {
      if (user) {
        this.user = user;
        this.isReady = true;
        console.log("user=", this.user)
        this.get_folder();
      }
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.getSavedSearch();
 
  }

  selectRole(ev: any) {
    console.log("selectRole=", ev.target.value)
    this.selectedRole = ev.target.value;
  }
  async clearFilter() {
    await this.modalController.dismiss({
      filter_type: this.filter_type,
      selectedRole: '',

      AE_functional_areas: '',
      AE_highest_t_ac : '',
      AE_largest_pl: '',
      private_equity: '',
      business_experiencing:'',
      turn_around_restructuring:'',
      OI_ftc: '',
      OI_ftf: '',
      OI_ptf:'',

      action: 'ok',

      industry_sector: [],
      business_subsector: [],
      consumer_subsector: [],
      energy_subsector: [],
      healthcare_subsector: [],
      industrials_subsector: [],
      technology_subsector:  [],
      fund_size_min: 0,
      fund_size_max: 0,
      control_orientation: '',

      isBusinessSector: false,
      isConsumerSector: false,
      isEnergySector: false,
      isHealthcareSector: false,
      isIndustrialsSector: false,
      isTechnologySector: false,
    });
  }

  async closeModal(action) {
    if (action == 'ok') {
      if(this.savedSearch == '' && this.folder_id > 0){
        this.presentToast("Please enter search name");
        return false;
      }

      if (this.industry_sector.length > 0) {

        if(this.savedSearch !=''){
          this.save_search();
        }
        
        await this.modalController.dismiss({
          filter_type: this.filter_type,
          selectedRole: this.selectedRole,
     
          AE_functional_areas : this.AE_functional_areas,
          AE_highest_t_ac : this.AE_highest_t_ac,
          AE_largest_pl : this.AE_largest_pl,
          private_equity: this.private_equity,
          business_experiencing: this.business_experiencing,
          turn_around_restructuring: this.turn_around_restructuring,
          OI_ftc: this.OI_ftc,
          OI_ftf: this.OI_ftf,
          OI_ptf: this.OI_ptf,

          action: action,
          industry_sector: this.industry_sector,
          business_subsector: this.business_subsector,
          consumer_subsector: this.consumer_subsector,
          energy_subsector: this.energy_subsector,
          healthcare_subsector: this.healthcare_subsector,
          industrials_subsector: this.industrials_subsector,
          technology_subsector: this.technology_subsector,
          fund_size_min: this.fund_size_min,
          fund_size_max: this.fund_size_max,
          control_orientation: this.control_orientation,

          isBusinessSector: this.isBusinessSector,
          isConsumerSector: this.isConsumerSector,
          isEnergySector: this.isEnergySector,
          isHealthcareSector: this.isHealthcareSector,
          isIndustrialsSector: this.isIndustrialsSector,
          isTechnologySector: this.isTechnologySector,
        });
      } else {
        this.presentToast("Please select Industry Sector");
      }
    } else {
      await this.modalController.dismiss({
        filter_type: this.filter_type,
        selectedRole: this.selectedRole,
        AE_functional_areas : this.AE_functional_areas,
        AE_highest_t_ac : this.AE_highest_t_ac,
        AE_largest_pl : this.AE_largest_pl,
        private_equity: this.private_equity,
        business_experiencing: this.business_experiencing,
        turn_around_restructuring: this.turn_around_restructuring,
        OI_ftc: this.OI_ftc,
        OI_ftf: this.OI_ftf,
        OI_ptf: this.OI_ptf,
        action: action,

        industry_sector: this.industry_sector,
        business_subsector: this.business_subsector,
        consumer_subsector: this.consumer_subsector,
        energy_subsector: this.energy_subsector,
        healthcare_subsector: this.healthcare_subsector,
        industrials_subsector: this.industrials_subsector,
        technology_subsector: this.technology_subsector,
        fund_size_min: this.fund_size_min,
        fund_size_max: this.fund_size_max,
        control_orientation: this.control_orientation,

        isBusinessSector: this.isBusinessSector,
        isConsumerSector: this.isConsumerSector,
        isEnergySector: this.isEnergySector,
        isHealthcareSector: this.isHealthcareSector,
        isIndustrialsSector: this.isIndustrialsSector,
        isTechnologySector: this.isTechnologySector,
      });
    }


  }

  selectIndustry(event) {
    console.log("event =", event.detail.value);
    let isIndustry = event.detail.value;
    if (isIndustry.length > 0) {

      if (isIndustry.indexOf('Business Services') !== -1) {
        this.isBusinessSector = true;
      } else {
        this.isBusinessSector = false;
      }

      if (isIndustry.indexOf('Consumer') !== -1) {
        this.isConsumerSector = true;
      } else {
        this.isConsumerSector = false;
      }

      if (isIndustry.indexOf('Energy, Power & Infrastructure') !== -1) {
        this.isEnergySector = true;
      } else {
        this.isEnergySector = false;
      }

      if (isIndustry.indexOf('Industrials') !== -1) {
        this.isIndustrialsSector = true;
      } else {
        this.isIndustrialsSector = false;
      }

      if (isIndustry.indexOf('Technology, Media & Telecom') !== -1) {
        this.isTechnologySector = true;
      } else {
        this.isTechnologySector = false;
      }

      if (isIndustry.indexOf('Healthcare') !== -1) {
        this.isHealthcareSector = true;
      } else {
        this.isHealthcareSector = false;
      }
    } else {
      this.isBusinessSector = false;
      this.isConsumerSector = false;
      this.isEnergySector = false;
      this.isHealthcareSector = false;
      this.isIndustrialsSector = false;
      this.isTechnologySector = false;
    }
  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });

    toast.present();
  }

  change_filter_type(event) {
    //this.clear_user_filter();
    this.filter_type = event.detail.value;
  }
  checkedPrivate(ev:any){
    const isChecked = ev.currentTarget.checked;
    if(isChecked == true){
      this.private_equity = 'Yes';
    }else{
      this.private_equity = '';
    }
    console.log(" this.private_equity : ",  this.private_equity);
  }

  checkedHyper(ev:any){
    const isChecked = ev.currentTarget.checked;
    if(isChecked == true){
      this.business_experiencing = 'Yes';
    }else{
      this.business_experiencing = '';
    }
    console.log(" this.business_experiencing : ",  this.business_experiencing);
  }

  checkedTurnaround(ev:any){
    const isChecked = ev.currentTarget.checked;
    if(isChecked==true){
      this.turn_around_restructuring = 'Yes';
    }else{
      this.turn_around_restructuring = '';
    }
    console.log("this.turn_around_restructuring : ", this.turn_around_restructuring);

  }

  savedChekbox(ev:any){
    setTimeout(() => {
      const isChecked = ev.target.checked;
      if(isChecked==true){
        this.isSavedInput = true;
        this.scrollToBottomFunction();
      }else{
        this.isSavedInput = false;
      }
    }, 100);
  }

  save_search(){
  
    console.log("industry_sector : ",this.industry_sector);
    this.storage.get('user').then((user) => {
      if (user) {
        let sendData: any = {
          saved_search_name       : this.savedSearch, 
          selectedRole            : this.selectedRole,
          token                   : user.token,
          industry_sector         : this.industry_sector,
          business_subsector      : this.business_subsector,
          consumer_subsector      : this.consumer_subsector,
          energy_subsector        : this.energy_subsector,
          healthcare_subsector    : this.healthcare_subsector,
          industrials_subsector   : this.industrials_subsector,
          technology_subsector    : this.technology_subsector,
  
          functional_areas        : this.AE_functional_areas,
          highest_title           : this.AE_highest_t_ac,
          pl_oversite             : this.AE_largest_pl,
          private_equity          : this.private_equity,
          hyper_growth            : this.business_experiencing, 
          turnaround              : this.turn_around_restructuring,
          OI_ftc                  : this.OI_ftc,
          OI_ftf                  : this.OI_ftf,
          OI_ptf                  : this.OI_ptf, 

          fund_size_min           : this.fund_size_min,
          fund_size_max           : this.fund_size_max,
          control_orientation     : this.control_orientation,
          folder_id               : this.folder_id,
        }
        console.log("sendData :", sendData);
        // return false;
        this.serviceForAllService.save_search(sendData).subscribe(res => {
          let result: any = [];
          result = res;
          console.log("result :", result);

        }, (err) => {
          console.log(err);
          let msg = err.error.errormsg;
          this.presentToast(msg);
          if (err.error.error_code == 'user_expire') {
            this.router.navigate(['/login']);
            this.storage.remove('user');
          }
        });
      } else {
        this.router.navigate(['/login']);
      }
    });

  }

  getSavedSearch(){
    this.storage.get('user').then((user) => {
      if (user) {
        this.serviceForAllService.get_saved_search(user.token).subscribe((result) => {
          let res: any = [];
          res = result;
          console.log("res :", res);
          this.saved_executive = res.saved_executive;
          this.saved_sponsor   = res.saved_sponsor;

        }, (err) => {
          console.log("error...", err);
          let msg = err.error.errormsg;
          this.presentToast(msg);
          if (err.error.errorcode == 'token_expired') {
            this.router.navigate(['/login']);
            this.storage.remove('user');
          }
        });
      }
    });
  }
  setPrefrencesExe(ev:any){
    let ind = ev.target.value;
    let row = this.saved_executive[ind];
    let saved_search = row.search_param;

    this.industry_sector = saved_search.industry_sector;
    this.business_subsector = saved_search.business_subsector;
    this.consumer_subsector = saved_search.consumer_subsector;
    this.energy_subsector = saved_search.energy_subsector;
    this.healthcare_subsector = saved_search.healthcare_subsector;
    this.industrials_subsector = saved_search.industrials_subsector;
    this.technology_subsector = saved_search.technology_subsector;

    this.AE_functional_areas = saved_search.functional_areas;
    this.AE_highest_t_ac = saved_search.highest_title;
    this.AE_largest_pl = saved_search.pl_oversite;
    this.private_equity = saved_search.private_equity
    this.business_experiencing = saved_search.hyper_growth
    this.turn_around_restructuring = saved_search.turnaround
    this.OI_ftc = saved_search.OI_ftc
    this.OI_ftf = saved_search.OI_ftf
    this.OI_ptf = saved_search.OI_ptf

  }

  setPrefrencesSponsor(ev:any){
    let ind = ev.target.value;
    let row = this.saved_sponsor[ind];
    let saved_search = row.search_param;

    this.industry_sector = saved_search.industry_sector;
    this.business_subsector = saved_search.business_subsector;
    this.consumer_subsector = saved_search.consumer_subsector;
    this.energy_subsector = saved_search.energy_subsector;
    this.healthcare_subsector = saved_search.healthcare_subsector;
    this.industrials_subsector = saved_search.industrials_subsector;
    this.technology_subsector = saved_search.technology_subsector;

    this.fund_size_min = saved_search.fund_size_min
    this.fund_size_max = saved_search.fund_size_max
    this.control_orientation = saved_search.control_orientation
  }
  
  scrollToBottomFunction() {
    setTimeout(() => {
      this.content.scrollToBottom(100);
    }, 400);
  }

 async folderCheckbox(ev:any){
    const isChecked = ev.currentTarget.checked;
    if(isChecked){
      this.isSaveFolder = true;
    }else{
      this.isSaveFolder = true;
    }
  }
  
  get_folder() {
    let sendData: any = {
      token: this.user.token,
    }
    console.log("sendData :", sendData);
    this.serviceForAllService.get_folders(sendData).subscribe(res => {
      this.isReady = true;
      let result: any = [];
      result = res;
      this.folders = result.folder;

    }, (err) => {
      console.log(err);
      this.loading.dismiss();
      let msg = err.error.errormsg;
      this.presentToast(msg);
      if (err.error.error_code == 'token_expired') {
        this.router.navigate(['/login']);
        this.storage.remove('user');
      }
    });
  }
  selectFolder(ev:any){
    console.log("folder id : ",ev.target.value);
    this.folder_id = ev.target.value;
  }
}
