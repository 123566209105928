import { Component, OnInit } from '@angular/core';
import { ServiceForAllService } from '../service-for-all.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ModalController, MenuController, LoadingController,AlertController,ToastController } from '@ionic/angular';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.page.html',
  styleUrls: ['./actions.page.scss'],
})
export class ActionsPage implements OnInit {
  user: any=[];
  feed_id: any;
  post_index: any;
  referral_page: any;
  loading: any; 
  results: any=[];
  feedDetails: any=[];
  isDel: Number=0;
  author_id: any;

  constructor(
    public serviceForAllService:ServiceForAllService,
    private router: Router,
    public route: ActivatedRoute,
    public popoverController: PopoverController,
    public storage:Storage,
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController
  ) { 
    this.storage.get('user').then((val) => {
      if(val!=null){
        this.user = val;
      }
    });
  }

  ngOnInit() {
    console.log("author_id: "+this.author_id);
    console.log('referral_page: '+this.referral_page);
  }

  goToReportAbuse(){
    this.feedDetails['feed_id'] = this.feed_id;
    this.feedDetails['post_index'] = this.post_index;
    this.feedDetails['referral_page'] = this.referral_page;
    let navigationExtras: NavigationExtras = {
      queryParams:this.feedDetails
    };
    this.router.navigate(['report-as-abuse'], navigationExtras);
    this.dismissPopover();
  }
  
  goToEditPost(){
    this.feedDetails['feed_id'] = this.feed_id;
    this.feedDetails['post_index'] = this.post_index;
    let navigationExtras: NavigationExtras = {
      queryParams:this.feedDetails
    };
    if(this.referral_page=='forums'){
      this.router.navigate(['add-edit-forum'], navigationExtras);
    }else{
      this.router.navigate(['add-edit-post'], navigationExtras);
    }
    this.dismissPopover();
  }

  async delete_post(){
    console.log("feed_id: "+this.feed_id);
    console.log("post_index: "+this.post_index);
    let msg;
    if(this.referral_page == 'forums'){
      msg = 'Are you sure to delete this forum?';
    }else{
      msg = 'Are you sure to delete activity post?';
    }
    const alert = await this.alertCtrl.create({
      header: 'CEO Discovery',
      message: msg,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.dismissPopover();
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.showLoader('Please wait...');
            
            this.serviceForAllService.deletePost(this.user.token, this.feed_id).subscribe(res => {
              let rs: any=[];
              rs = res;
              this.loading.dismiss();
              this.isDel = 1;
              this.dismissPopover();
              let msg = rs.msg;
              this.presentToast(msg);
            }, err => {
              console.log(err);
              this.loading.dismiss();
              this.dismissPopover();
              this.presentToast(err.error.error_msg);
              if(err.error.error_code == 'user_expire'){
                this.router.navigate(['/login']);
              }
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async dismissPopover() {
    await this.popoverController.dismiss({post_id: this.feed_id, post_index:this.post_index, isDel:this.isDel});
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  async showLoader(msg){
    this.loading = await this.loadingCtrl.create({message: msg});
    this.loading.present();
  }

}
