import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, NavController, MenuController, ToastController, Platform, ModalController, NavParams } from '@ionic/angular';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-add-schedule-call',
  templateUrl: './add-schedule-call.page.html',
  styleUrls: ['./add-schedule-call.page.scss'],
})
export class AddScheduleCallPage implements OnInit {

  addScheduleCallForm: FormGroup;
  loginUser:any = [];
  currentDate: any;
  futureDate: any;
  minT:any;
  maxT:any;
  loading:any;
  uid:number;
  
  validation_messages = {
    'date': [
      { type: 'required', message: 'Date is required.' },
    ],
    'time': [
      { type: 'required', message: 'Time is required.' },
    ],
    'title': [
      { type: 'required', message: 'Title is required.' },
    ],
    'description': [
      { type: 'required', message: 'Description is required.' },
    ]
  };

  constructor(
    public modalController : ModalController,
    public storage: Storage,
    public serviceForAllService : ServiceForAllService,
    public loadingCtrl : LoadingController,
    public toastController: ToastController,
    private route: ActivatedRoute,
    private router: Router,
    private navParams: NavParams,
  ) {
    this.currentDate = moment().format('YYYY-MM-DD');
    this.futureDate = moment(this.currentDate).add(10, 'y').format('YYYY-MM-DD');

    this.minT = moment().format('YYYY-MM-DD');
    this.maxT = moment(this.currentDate).add(10, 'y').format('YYYY-MM-DD');

    this.uid = this.navParams.get('uid');
    console.log("this.uid :", this.uid);

    this.storage.get('user').then((user) => {
      this.loginUser = user;
    })
  }

  ngOnInit() {
    this.addScheduleCallForm = new FormGroup({
      'date': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'time': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'title': new FormControl('', Validators.compose([
        Validators.required
      ])),
      'description': new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
  }
  async closeModal(event_id, action) {
    await this.modalController.dismiss({action: action});
  }

  addScheduleCall(){

    console.log(this.addScheduleCallForm.value);
    this.storage.get('user').then((user) => {
      if (user) {
        this.showLoader('');
        let date = this.changeToDate(this.addScheduleCallForm.value.date);
        let time = this.changeToTime(this.addScheduleCallForm.value.time);

        let sendData: any = {
          token         : user.token,
          uid           : this.uid,
          date          : date,
          time          : time,
          title         : this.addScheduleCallForm.value.title,
          description   : this.addScheduleCallForm.value.description,
          status        : 0,
          view_type     : 0, 
        }
        console.log("sendData :",sendData);
        // return false;
        this.serviceForAllService.add_schedule_call(sendData).subscribe(res => {
          this.loading.dismiss();
          let result:any = [];
          result = res;
          console.log("result :",result);
          this.presentToast(result.msg);
        
           this.modalController.dismiss({action: 'ok'});
     
        }, (err) => {
          console.log(err);
          this.loading.dismiss();
          let msg = err.error.errormsg;
          this.presentToast(msg);
          if (err.error.error_code == 'user_expire') {
            this.router.navigate(['/login']);
            this.storage.remove('user');
          }
        });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  changeToDate(dt) {
    if (!dt || dt == '' || dt == 'Invalid date' || dt == undefined) {
      return '';
    }
    let chk = moment(dt, 'YYYY-MM-DD', true).isValid();
    if (chk) {
      console.log("already valid");
      return dt;
    } else {
      console.log('Before; ', dt);
      let t = moment(dt, "YYYY-MM-DDTHH:mm:ssZ").format('YYYY-MM-DD');
      console.log('After; ', t);
      return t;
    }
  }
  changeToTime(dt) {
    if (!dt || dt == '' || dt == 'Invalid date' || dt == undefined) {
      return '';
    }
    let chk = moment(dt, 'HH:mm:ss', true).isValid();
    if (chk) {
      console.log("already valid");
      return dt;
    } else {
      console.log('Before; ', dt);
      let t = moment(dt, "YYYY-MM-DDTHH:mm:ssZ").format('HH:mm:ss');
      console.log('After; ', t);
      return t;
    }
  }

  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({ message: msg,backdropDismiss: false});
    this.loading.present();
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom',
      cssClass: 'custom_toast_color'
    });
    toast.present();
  }
}
