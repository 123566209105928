import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CapturePhotoPageRoutingModule } from './capture-photo-routing.module';

import { CapturePhotoPage } from './capture-photo.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CapturePhotoPageRoutingModule
  ],
  declarations: [CapturePhotoPage]
})
export class CapturePhotoPageModule {}
