import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, ToastController, ActionSheetController, NavParams } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-save-to-folder',
  templateUrl: './save-to-folder.page.html',
  styleUrls: ['./save-to-folder.page.scss'],
})
export class SaveToFolderPage implements OnInit {

  loginUser:any = [];
  folder_id:number = 0;
  loading:any;
  folders:any = [];
  count:number = 0;
  isReady:boolean = false;
  selected_folder_id : any = '';
  group_id:number = 0;
  type:string = '';
  uid:number = 0;

  constructor(
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    private router: Router,
    public route: ActivatedRoute,
    public actionSheetController: ActionSheetController,
    public navParams: NavParams
  ) { 


    this.group_id = this.navParams.get('group_id');
    this.type = this.navParams.get('type');
    this.uid = this.navParams.get('uid');
    console.log("type", this.type);

    this.storage.get('user').then((user) => {
      if(user){
        this.loginUser = user;
        this.get_folder();
      }else{
        this.router.navigate(['/login'])
      }
     
    })
  }

  ngOnInit() {
  }

  select_folder(index){
    
    if(this.selected_folder_id == '' || this.folders[index].folder_id != this.selected_folder_id){
      this.selected_folder_id = this.folders[index].folder_id;
    }else{
      this.selected_folder_id = '';
    }
    console.log("selected_folder_id:", this.selected_folder_id);
  }
  async closeModal(event_id, action) {
    await this.modalController.dismiss({action: action});
  }
  get_folder() {
    let sendData: any = {
      token: this.loginUser.token,
      folder_id: this.folder_id,
    }
    this.showLoader('');
  
    console.log("sendData :", sendData);
    this.serviceForAllService.get_folders(sendData).subscribe(res => {
      this.loading.dismiss();
      this.isReady = true;
      let result: any = [];
      result = res;
      this.folders = result.folder;
      this.count = result.count;
      // this.presentToast(result.msg);

    }, (err) => {
      console.log(err);
      this.loading.dismiss();
      let msg = err.error.errormsg;
      this.presentToast(msg);
      if (err.error.error_code == 'token_expired') {
        this.router.navigate(['/login']);
        this.storage.remove('user');
      }
    });
  }

  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({ message: msg, backdropDismiss: false });
    this.loading.present();
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }
   saveToFolder(){
    if(this.selected_folder_id == ''){
      this.presentToast("Please select the folder");
      return false;
    }

    let sendData: any = {
      token: this.loginUser.token,
      group_id: this.group_id,
      folder_id: this.selected_folder_id,
      type : this.type,
      uid : this.uid
    }
    this.showLoader('');
    console.log("sendData :", sendData);

    this.serviceForAllService.save_to_folder(sendData).subscribe(res => {
      this.loading.dismiss();
      let result: any = [];
      result = res;
      console.log("result :",result);
      this.presentToast(result.msg);
      this.modalController.dismiss({ action: 'ok', isSaveFolder : result.isSaveFolder, folder_id: result.folder_id });

    }, (err) => {
      console.log(err);
      this.loading.dismiss();
      let msg = err.error.errormsg;
      this.presentToast(msg);
      if (err.error.error_code == 'token_expired') {
        this.router.navigate(['/login']);
        this.storage.remove('user');
      }
    });


  }

}
