import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-reminder-details',
  templateUrl: './reminder-details.page.html',
  styleUrls: ['./reminder-details.page.scss'],
})
export class ReminderDetailsPage implements OnInit {

  public reminder = this.navParams.get('reminder');
  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    ) { 
    console.log("notification: ",this.reminder)
    console.log("occurence ", this.reminder.occurence );
  }

  ngOnInit() {
  }
  async closeModal(action) {

    await this.modalController.dismiss({ action: action });

  }

}
