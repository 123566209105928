import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AlertController, LoadingController, ToastController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scheduled-call-details',
  templateUrl: './scheduled-call-details.page.html',
  styleUrls: ['./scheduled-call-details.page.scss'],
})
export class ScheduledCallDetailsPage implements OnInit {

  public callsDetails = this.navParams.get('scheduled_call_details');
  public type = this.navParams.get('type');
  loginUser:any = [];

  constructor(
     private navParams: NavParams,
     public modalController : ModalController,
     public storage: Storage,
     public router: Router,
    ) { 
      console.log("type: ",this.type);
      this.storage.get('user').then((user) => {
        if (user) {
          this.loginUser = user;
        } else {
          this.router.navigate(['/login']);
        }
      });
      console.log("scheduled_call_details :",this.callsDetails);
  }

  ngOnInit() {
  }


  async closeModal(action) {
    await this.modalController.dismiss({ action: action });
  }

}
