import { Component, OnInit } from '@angular/core';
import { NavController, ModalController, MenuController, LoadingController, AlertController, ToastController, NavParams } from '@ionic/angular';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-add-edit-folder',
  templateUrl: './add-edit-folder.page.html',
  styleUrls: ['./add-edit-folder.page.scss'],
})
export class AddEditFolderPage implements OnInit {
  loginUser: any = [];
  addEditForm: FormGroup;
  loading: any;
  folder_title: string = '';
  folder_id: any = '';
  action_type: string = '';
  parent_folder_id: any = '';
  uid: number = 0;
  keepPrivate: boolean = false;
  created_by: string = '';
  company_name: string = '';

  constructor(
    public modalController: ModalController,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    public loadingCtrl: LoadingController,
    public toastController: ToastController,
    private navParams: NavParams,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.parent_folder_id = this.navParams.get('folder_id');
    let item = this.navParams.get('item');
    this.uid = this.navParams.get('uid');
    this.created_by = this.navParams.get('created_by');

    if (item != undefined) {
      this.parent_folder_id = item.post_parent;
      this.folder_id = item.folder_id;
      this.folder_title = item.folder_title;
      this.keepPrivate = item.keep_private;
      this.created_by = item.created_by;
      this.company_name = item.company_name;

    }
    console.log("parent_folder_id", this.parent_folder_id);
    this.storage.get('user').then((user) => {
      if (user) {
        this.loginUser = user;
      } else {
        this.router.navigate(['/login']);
      }

    })
    //   this.get_folder();
    console.log("folder_id: ", this.folder_id);
    console.log("uid", this.uid);
  }
  ionViewWillEnter() {

  }
  ngOnInit() {
  }
  keepPrivateCheck(event: any) {
    console.log(event.currentTarget.checked);
    const isChecked = event.currentTarget.checked;
    if (isChecked == true) {
      this.keepPrivate = true;
    } else {
      this.keepPrivate = false;
    }

  }

  addEditFolder() {
    if (this.folder_title == '') {
      this.presentToast("Please enter folder name");
      return false;
    }
    this.storage.get('user').then((user) => {
      if (user) {
        this.showLoader('');
        let sendData: any = {

          token: user.token,
          folder_title: this.folder_title,
          folder_id: this.folder_id,
          parent_folder_id: this.parent_folder_id,
          action_type: this.action_type,
          uid: this.uid,
          keepPrivate: this.keepPrivate,
          created_by: this.created_by,
          company_name: this.company_name,

        }
        console.log("sendData :", sendData);

        this.serviceForAllService.add_edit_folder(sendData).subscribe(res => {
          this.loading.dismiss();
          let result: any = [];
          result = res;
          console.log("result :", result);
          this.presentToast(result.msg);

          this.modalController.dismiss({ action: 'ok' });

        }, (err) => {
          console.log(err);
          this.loading.dismiss();
          let msg = err.error.errormsg;
          this.presentToast(msg);
          if (err.error.error_code == 'user_expire') {
            this.router.navigate(['/login']);
            this.storage.remove('user');
          }
        });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  async closeModal(action) {
    await this.modalController.dismiss({ action: action });
  }

  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({ message: msg, backdropDismiss: false });
    this.loading.present();
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }

}
