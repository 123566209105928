import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-view-permission',
  templateUrl: './view-permission.page.html',
  styleUrls: ['./view-permission.page.scss'],
})
export class ViewPermissionPage implements OnInit {
  public permissions = this.navParams.get('permissions');

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
  ) {
    console.log("permissions: ", this.permissions)
  }

  ngOnInit() {
  }

  async closeModal(action) {
    await this.modalController.dismiss({ action: action });
  }

}
