import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ScheduledCallDetailsPageRoutingModule } from './scheduled-call-details-routing.module';

import { ScheduledCallDetailsPage } from './scheduled-call-details.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ScheduledCallDetailsPageRoutingModule
  ],
  declarations: [ScheduledCallDetailsPage]
})
export class ScheduledCallDetailsPageModule {}
