import { Component, OnInit } from '@angular/core';
import { ServiceForAllService } from '../service-for-all.service';
import { Storage } from '@ionic/storage';
import { Router, NavigationExtras } from '@angular/router';
import { ToastController, ModalController, NavController, NavParams, MenuController, LoadingController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-likes-list',
  templateUrl: './likes-list.page.html',
  styleUrls: ['./likes-list.page.scss'],
})
export class LikesListPage implements OnInit {

  user:any;
  hits='Likes';
  loading: any; 
  usersList: any =[];
  post_id: any;
  spinner: boolean;

  // public feed_id = this.navParams.get('post_id');

  constructor(
    public storage:Storage,
    public router:Router,
    private navParams: NavParams,
    public serviceForAllService:ServiceForAllService, 
    public loadingCtrl: LoadingController, 
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public menu: MenuController,
    public navCtrl:NavController,
    public toastController:ToastController
  ) {
    this.storage.get('user').then((user) => {
      console.log(" USER LOGIN "+JSON.stringify(user));
      if (user) {
        this.user=user;
      } 
    });
    this.post_id = this.navParams.get('post_id');
    this.get_users_who_liked_post(this.post_id);
   }

  ngOnInit() {
  }

  // ionViewWillEnter(){
  //   this.storage.get('user').then((user) => {
  //     if (user) {
  //       this.user=user;
  //     } 
  //   });
  // }

  get_users_who_liked_post(post_id){
    // this.showLoader();   
    this.spinner = true;
    this.storage.get('user').then((user) => {
      if (user) {
        this.serviceForAllService.get_users_who_liked_post(post_id, user.token).subscribe((res) => {
              let results:any=[];
              results = res;

              this.usersList = results.users_list;
              console.log('users_list ====',this.usersList );
              // this.loading.dismiss();
              this.spinner = false;
          }, (err) => {
              // this.loading.dismiss();
              this.spinner = false;
              console.log("error...",err);
              let msg= err.error.errormsg;
              this.closeModal();
              this.presentAlert(msg,['/login']);
        });
      }
    });
  }

  gotoOtherUserProfile(uid){
    this.modalController.dismiss();

    let userDetails: any=[];
    userDetails['uid'] = uid;

    let navigationExtras: NavigationExtras = {
      queryParams: userDetails
    };
    this.router.navigate(['otheruser-profile'], navigationExtras);
  }

  openChatPage(user_id, title, user_img){
    this.modalController.dismiss();
    
    let userPro = {
      id:user_id,
      title:title,
      user_img:user_img,
    };

    let Currentuser = {
      id:this.user.user_id,
      display_name:this.user.nickname,
      user_img:this.user.user_avatar,
    };

    let navigationExtras: NavigationExtras = { 
      queryParams: {
        //  special: JSON.stringify(workout),
        secondUser:JSON.stringify(userPro),
        currentUser:JSON.stringify(Currentuser),
        fromMy:true
      } 
    };
    this.navCtrl.navigateForward(['/chat'],navigationExtras);
  }

  async closeModal() {
   await this.modalController.dismiss();
  }

  async presentAlert(msg,page) {
    let alert = await this.alertCtrl.create({
      header: 'Alert',
      message: msg,
      buttons: [{
        text: 'OK',
        handler: () => {
          this.router.navigate(page);
        }
      }
      ]
     
    });

    await alert.present();
  }

  async showLoader(){
    this.loading = await this.loadingCtrl.create({message: 'Please wait...'});
    this.loading.present();
  }

}
