import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, ModalController, MenuController, LoadingController, AlertController, Platform, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-add-professional-users',
  templateUrl: './add-professional-users.page.html',
  styleUrls: ['./add-professional-users.page.scss'],
})
export class AddProfessionalUsersPage implements OnInit {

  
  // public selectedUsers = this.navParams.get('selected_users');

  // selectedUsers: any = [];
  loading_spinner: boolean;
  page = 1;
  serach_keyword: any;
  user: any = [];
  loading: any;
  usersList: any = [];
  users_count: number = 0;
  msg_user_not_found: any = '';

  constructor(
    public router: Router,
    public loadingCtrl: LoadingController,
    public modalController: ModalController,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    public toastController: ToastController,
    private navParams: NavParams,
    public alertController : AlertController,
  ) {
    this.storage.get('user').then((user) => {
      if (user) {
        this.user = user;
      }else{
        this.presentToast('', ['/login']);
      }
    });
    // if(this.selectedUser.length > 0){
    //   this.selectedUsers = this.selectedUser;
    // }else{
    //   this.selectedUsers = [];
    // }
    
    // console.log("post_id :", this.post_id);
    // console.log("author_id :",this.author_id);
  }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.getProfessionals();
  }

  async closeModal(action) {
    await this.modalController.dismiss({ action: action });
  }

  getProfessionals() {
    this.storage.get('user').then((user) => {
      if (user) {
        this.loading_spinner = true;
        this.page = 1;
        let sendData: any = {
          page: this.page,
          token: user.token,
          serach_keyword: this.serach_keyword,
          selectedRole: 'executive'
        }
        this.serviceForAllService.get_all_professionals(sendData).subscribe((result) => {
          this.loading_spinner = false;
          let res: any = [];
          res = result;
          this.usersList = res.users_list;

          // let i=0;
          // this.usersList.forEach((p) => {
          //   if(this.selectedUsers.indexOf(p.user_id) > -1){
          //     this.usersList[i].isChecked = true;
          //   }else{
          //     this.usersList[i].isChecked = false;
          //   }
          //   i++;
          // });

          this.users_count = res.count;
          if (this.users_count == 0) {
            this.msg_user_not_found = "No user found";
          }

        }, (err) => {
          this.loading_spinner = false;
          console.log("error...", err);
          let msg = err.error.errormsg;
          // this.storage.remove('user');
          // this.presentToast(msg, ['/login']);
          this.presentToast(msg, '');
        });
      }
    });
  }
  getMoreProfessionals(event) {

    this.storage.get('user').then((user) => {
      if (user) {
        this.loading_spinner = true;
        this.page++;

        let sendData: any = {
          page: this.page,
          token: user.token,
          serach_keyword: this.serach_keyword,
          selectedRole: 'executive'
        }
        this.serviceForAllService.get_all_professionals(sendData).subscribe((result) => {
          this.loading_spinner = false;
          let res: any = [];
          res = result;
          this.usersList = [...this.usersList, ...res.users_list];
          console.log("this.usersList count: " + this.usersList.length);

          // let i=0;
          // this.usersList.forEach((p) => {
          //   if(this.selectedUsers.indexOf(p.user_id) > -1){
          //     this.usersList[i].isChecked = true;
          //   }else{
          //     this.usersList[i].isChecked = false;
          //   }
          //   i++;
          // });
          
          this.users_count = res.count;
          if (this.users_count == 0) {
            this.msg_user_not_found = "No more users";
          }
          event.target.complete();

        }, (err) => {
          event.target.complete();
          this.loading_spinner = false;
          console.log("error...", err);
          let msg = err.error.errormsg;
          this.storage.remove('user');
          this.presentToast(msg, ['/login']);
        });
      }
    });
  }
  serach_results() {
    this.getProfessionals();
  }

  async presentToast(msg, page) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });

    toast.present().then(
      x => {
        console.log("Inside");
        this.router.navigate(page);
      }
    );
  }
  // selectUser(user_id) {
  //   console.log("user_id :", user_id);
  //   let i = 0;
  //   let indx;
  //   indx = this.selectedUsers.indexOf(user_id);
  //   if (indx > -1) {
  //     this.selectedUsers.splice(indx, 1);
  //     console.log("selectedUsers =", this.selectedUsers);
  //     this.selectedUsers.forEach((s) => {
  //       if (s.user_id == user_id) {
  //         this.selectedUsers.splice(i, 1);
  //       }
  //       i++;
  //     });

  //   }
  //   else {
  //     this.selectedUsers.push(user_id);
  //   }
  //   console.log("selectedUsers =", this.selectedUsers);
  // }


  async inviteUsersChangeStatus(uid, capabilities, index, action){

    const alert = await this.alertController.create({
      cssClass: 'capabilities_options',
      header: 'Confirm',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            console.log('Confirm Ok',data);
            this.showLoader("");
            let sendData: any = {
              token: this.user.token,
              uid: uid,
              capabilities: '',
              action: action,
            }

            this.serviceForAllService.invite_user(sendData).subscribe((result) => {
              this.loading.dismiss();
              let res: any = [];
              let invite_status;
              res = result;
              console.log("res :", res);
              this.presentToast(res.msg, '');
              invite_status = res.invite_status;
              this.usersList[index].invite_status = invite_status;

              // this.modalController.dismiss({ action: 'ok' });

            }, (err) => {
              this.loading.dismiss();
              console.log("error...", err);
              let msg = err.error.msg;
              if (err.error.error_code = 'token_expired') {
                // this.storage.remove('user');
                // this.presentToast(msg, ['/login']);
              }
              this.presentToast(msg, '');

            });
        
          }
        }
      ]
    });

    await alert.present();
  }

  async inviteUsers(uid, capabilities, index, action){
    // let indx, act;
    // indx = index;
    // act = action;

    const alert = await this.alertController.create({
      cssClass: 'capabilities_options',
      header: 'Set Permissions',
      inputs: capabilities,
      
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            console.log('Confirm Ok',data);
            if(data.length <= 0){
              this.presentToast('Please select permissons', '');
              return false;
            }
            this.showLoader("");
            let sendData: any = {
              token: this.user.token,
              uid: uid,
              capabilities: data,
              action: action,
            }

            this.serviceForAllService.invite_user(sendData).subscribe((result) => {
              this.loading.dismiss();
              let res: any = [];
              let invite_status;
              res = result;
              console.log("res :", res);
              this.presentToast(res.msg, '');
              invite_status = res.invite_status;
              this.usersList[index].invite_status = invite_status;
              if(action=='update'){
                this.usersList[index].capabilities =res.capabilities;
              }
              // this.modalController.dismiss({ action: 'ok' });

            }, (err) => {
              this.loading.dismiss();
              console.log("error...", err);
              let msg = err.error.msg;
              if (err.error.error_code = 'token_expired') {
                // this.storage.remove('user');
                // this.presentToast(msg, ['/login']);
              }
              this.presentToast(msg, '');

            });
        
          }
        }
      ]
    });

    await alert.present();
  }


  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({ message: msg, backdropDismiss: false });
    this.loading.present();
  }

}
