import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddScheduleCallPageRoutingModule } from './add-schedule-call-routing.module';

import { AddScheduleCallPage } from './add-schedule-call.page';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    AddScheduleCallPageRoutingModule
  ],
  declarations: [AddScheduleCallPage]
})
export class AddScheduleCallPageModule {}
