import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule } from '@ionic/storage';

import { HttpClientModule } from '@angular/common/http'; 
// import { HttpModule } from '@angular/http'

import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { File } from '@ionic-native/file/ngx';

import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { ActionSheet, ActionSheetOptions } from '@ionic-native/action-sheet/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { VideoEditor,CreateThumbnailOptions } from '@ionic-native/video-editor/ngx';
import { ActionsPageModule } from './actions/actions.module';
import { LikesListPageModule } from './likes-list/likes-list.module';
import { PopoverSearchoptionsPageModule } from './popover-searchoptions/popover-searchoptions.module';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { VideoPopupPageModule } from './video-popup/video-popup.module';
import { TagUserPopupPageModule } from './tag-user-popup/tag-user-popup.module';
import { SearchFilterPageModule } from './search-filter/search-filter.module';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { CapturePhotoPageModule } from './capture-photo/capture-photo.module';
import { AddUsersGroupPageModule } from './add-users-group/add-users-group.module';
import { AddScheduleCallPageModule } from './add-schedule-call/add-schedule-call.module';
import { ScheduledCallDetailsPageModule } from './scheduled-call-details/scheduled-call-details.module';
import { ReminderDetailsPageModule } from './reminder-details/reminder-details.module';
import { ViewPermissionPageModule } from './view-permission/view-permission.module';
import { AddEditFolderPageModule } from './add-edit-folder/add-edit-folder.module';
import { DemoonePageModule } from './demoone/demoone.module';
import { SaveToFolderPageModule } from './save-to-folder/save-to-folder.module';
import { AddProfessionalUsersPageModule } from './add-professional-users/add-professional-users.module';
import { ProfessionalsPopoverPageModule } from './professionals-popover/professionals-popover.module';
import { ReminderModalPageModule } from './reminder-modal/reminder-modal.module';
import { ShareFolderPageModule } from './share-folder/share-folder.module';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    ActionsPageModule,
    LikesListPageModule,
    VideoPopupPageModule,
    TagUserPopupPageModule,
    SearchFilterPageModule,
    PopoverSearchoptionsPageModule,
    NgxIonicImageViewerModule,
    CapturePhotoPageModule,
    AddUsersGroupPageModule,
    AddScheduleCallPageModule,
    ScheduledCallDetailsPageModule,
    ReminderDetailsPageModule,
    ViewPermissionPageModule,
    AddEditFolderPageModule,
    DemoonePageModule,
    SaveToFolderPageModule,
    AddProfessionalUsersPageModule,
    ProfessionalsPopoverPageModule,
    ReminderModalPageModule,
    ShareFolderPageModule,
    
  ],
  providers: [
    StatusBar,
    SplashScreen,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      PhotoViewer,
      StreamingMedia,
      File,
      Camera,
      FileTransfer,
      ActionSheet,
      Crop,
      MediaCapture,
      VideoEditor,
      IOSFilePicker,
      FileChooser,
      OneSignal,
      Device,
      AndroidPermissions,
      Geolocation,
      Keyboard,
      PreviewAnyFile,
      FileOpener,
      FingerprintAIO,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
