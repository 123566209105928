import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router, NavigationExtras } from '@angular/router';
import { ToastController, ModalController, NavController, NavParams, MenuController, LoadingController, AlertController } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { Events } from '../events';

@Component({
  selector: 'app-tag-user-popup',
  templateUrl: './tag-user-popup.page.html',
  styleUrls: ['./tag-user-popup.page.scss'],
})
export class TagUserPopupPage implements OnInit {

  user: any;
  users_list: any = [];
  count: any;
  loading: any;
  // post_id: number = 0;
  searchedUser: any;

  public selectedUsers = this.navParams.get('selected_users');
  public checkedUsers = this.navParams.get('checked_users');

  constructor(
    public storage: Storage,
    public router: Router,
    private navParams: NavParams,
    public loadingCtrl: LoadingController,
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public menu: MenuController,
    public navCtrl: NavController,
    public toastController: ToastController,
    public serviceForAllService: ServiceForAllService,
    public events: Events
  ) {
    // this.post_id = this.navParams.get('post_id');
    // console.log('this.post_id: ' + this.post_id);
    console.log('this.selectedUsers: ' + this.selectedUsers);
    console.log('this.checkedUsers: ' + this.checkedUsers);
    this.get_my_followers_and_followings();
  }

  ngOnInit() {
  }

  async closeModal(action) {
    await this.modalController.dismiss({selected_users:this.selectedUsers, checked_users:this.checkedUsers, action: action});
  }


  get_my_followers_and_followings() {
    this.storage.get('user').then((user) => {
      this.showLoader();
      if (user) {
        this.serviceForAllService.get_my_followers_and_followings(user.token).subscribe((res) => {
          this.loading.dismiss();
          // console.log('result ====', res);
          let result: any = [];

          result = res;
          this.users_list = result.users_list;
          console.log('users_list before ====', this.users_list);
          this.count = result.count;

          let i=0;
          this.users_list.forEach((u) => {
            if(this.selectedUsers.indexOf(u.user_id) > -1){
              this.users_list[i].isChecked = true;
            }else{
              this.users_list[i].isChecked = false;
            }
            i++;
          });

          console.log('users_list after ====', this.users_list);
          
        }, (err) => {
          this.loading.dismiss();
          console.log("error...", err);
          let msg = err.error.errormsg;
          this.presentToast(msg, '');
        });
      } else {
        this.loading.dismiss();
        let msg = "Invalid token, Please try again.";
        this.presentToast(msg, ['/login']);
      }
    });
  }

  sortUsersListData(event){
    this.searchedUser = event.target.value.toLowerCase();
    // console.log(this.searchedUser);
    // if (this.searchedUser == '') {
    //   this.get_my_followers_and_followings();
    // }
    this.count = 0;
    this.users_list.forEach(item => {

      const one = item.name.toLowerCase().indexOf(this.searchedUser) > -1;
      // const two = item.msg_data.message.toLowerCase().indexOf(this.searchedChatUser) > -1;
      //const three = item.report_status_show.toLowerCase().indexOf(this.searchedChatUser) > -1;

      if(one==true){
        item.is_display = true;
      }else{
        item.is_display = false;
      }

      if(item.is_display == false){
        this.count = this.count + 1;
      }

    });
  }

selectUser(ev, user_id, name){
    let i=0;
    let indx;
    indx = this.selectedUsers.indexOf(user_id);
    if(indx > -1){
      this.selectedUsers.splice(indx, 1);

      this.checkedUsers.forEach((s) => {
        if(s.user_id == user_id){
          this.checkedUsers.splice(i,1);
        }
        i++;
      });
    }
    else{
      this.selectedUsers.push(user_id);

      let user_data:any = {};
      user_data.user_id = user_id;
      user_data.name = name;
      this.checkedUsers.push(user_data);
    }

    console.log("selectedUsers: "+this.selectedUsers);
  
    console.log("checkedUsers: "+JSON.stringify(this.checkedUsers));
    
  }

  remove_tagged_user(user_id){
    console.log('removed user_id: '+user_id);
    let i=0;
    let indx;
    console.log("bbb selectedUsers: "+this.selectedUsers);
  
    console.log("bbb checkedUsers: "+JSON.stringify(this.checkedUsers));
    indx = this.selectedUsers.indexOf(user_id);
    if(indx > -1){
      this.selectedUsers.splice(indx, 1);

      this.checkedUsers.forEach((s) => {
        if(s.user_id == user_id){
          this.checkedUsers.splice(i,1);
        }
        i++;
      });
    }

    let j=0;
    this.users_list.forEach((u) => {
      if(this.selectedUsers.indexOf(u.user_id) > -1){
        this.users_list[j].isChecked = true;
      }else{
        this.users_list[j].isChecked = false;
      }
      j++;
    });
    
    console.log("aaa selectedUsers: "+this.selectedUsers);
  
    console.log("aaa checkedUsers: "+JSON.stringify(this.checkedUsers));
  }

  async presentToast(msg, page) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
    this.router.navigate(page);
  }

  async showLoader() {
    this.loading = await this.loadingCtrl.create({ message: 'Please wait...' });
    this.loading.present();
  }

}
