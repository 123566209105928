import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router, NavigationExtras } from '@angular/router';
import { ToastController, ModalController, NavController, NavParams, MenuController, LoadingController, AlertController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.page.html',
  styleUrls: ['./video-popup.page.scss'],
})
export class VideoPopupPage implements OnInit {

  user:any;
  loading: any; 
  url: any;
  video_thumbnail: any='';
  is_youtube: any='no';
  youtube_url: any='';

  constructor(
    public storage:Storage,
    public router:Router,
    private navParams: NavParams,
    public loadingCtrl: LoadingController, 
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public menu: MenuController,
    public navCtrl:NavController,
    public toastController:ToastController,
    public sanitizer: DomSanitizer
  ) { 
    this.url = this.navParams.get('url');
    this.video_thumbnail = this.navParams.get('video_thumbnail');
    this.is_youtube = this.navParams.get('is_youtube');
    if(this.is_youtube == 'yes'){
      this.youtube_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
  }

  ngOnInit() {
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
  
}
