import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SaveToFolderPage } from './save-to-folder.page';

const routes: Routes = [
  {
    path: '',
    component: SaveToFolderPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SaveToFolderPageRoutingModule {}
