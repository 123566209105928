import { Component, OnInit } from '@angular/core';
import { NavController, ModalController, MenuController, LoadingController, AlertController, ToastController, ActionSheetController } from '@ionic/angular';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-demoone',
  templateUrl: './demoone.page.html',
  styleUrls: ['./demoone.page.scss'],
})
export class DemoonePage implements OnInit {
  loginUser:any = [];
  folder_id:number = 0;
  loading:any;
  folders:any = [];
  title:string= '';
  count:number = 0;
  isReady:boolean = false;
  selected_folder_id : any = '';
  constructor(
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    private router: Router,
    public route: ActivatedRoute,
    public actionSheetController: ActionSheetController
  ) { 
    this.storage.get('user').then((user) => {
      if(user){
        this.loginUser = user;
        this.get_folder();
      }else{
        this.router.navigate(['/login'])
      }
     
    })
  }

  ngOnInit() {
  }

  select_folder(index){
    
    if(this.selected_folder_id == '' || this.folders[index].folder_id != this.selected_folder_id){
      this.selected_folder_id = this.folders[index].folder_id;
    }else{
      this.selected_folder_id = '';
    }
    console.log("selected_folder_id:", this.selected_folder_id);
  }
  async closeModal(event_id, action) {
    await this.modalController.dismiss({action: action});
  }
  get_folder() {
    let sendData: any = {
      token: this.loginUser.token,
      folder_id: this.folder_id,
    }
    this.showLoader('');
  
    console.log("sendData :", sendData);
    this.serviceForAllService.get_folders(sendData).subscribe(res => {
      this.loading.dismiss();
      this.isReady = true;
      let result: any = [];
      result = res;
      this.folders = result.folder;
      this.title = result.title;
      this.count = result.count;
      console.log("title :", this.title);
      // this.presentToast(result.msg);

    }, (err) => {
      console.log(err);
      this.loading.dismiss();
      let msg = err.error.errormsg;
      this.presentToast(msg);
      if (err.error.error_code == 'user_expire') {
        this.router.navigate(['/login']);
        this.storage.remove('user');
      }
    });
  }

  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({ message: msg, backdropDismiss: false });
    this.loading.present();
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }

}
