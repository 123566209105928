import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SaveToFolderPageRoutingModule } from './save-to-folder-routing.module';

import { SaveToFolderPage } from './save-to-folder.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SaveToFolderPageRoutingModule
  ],
  declarations: [SaveToFolderPage]
})
export class SaveToFolderPageModule {}
