import { Component, OnInit } from '@angular/core';
import { ServiceForAllService } from '../service-for-all.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ModalController, MenuController, LoadingController,AlertController,ToastController } from '@ionic/angular';

@Component({
  selector: 'app-popover-searchoptions',
  templateUrl: './popover-searchoptions.page.html',
  styleUrls: ['./popover-searchoptions.page.scss'],
})
export class PopoverSearchoptionsPage implements OnInit {

  user: any=[];
  feed_id: any;
  loading: any; 

  constructor(
    public serviceForAllService:ServiceForAllService,
    private router: Router,
    public route: ActivatedRoute,
    public popoverController: PopoverController,
    public storage:Storage,
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController
  ) { 
    this.storage.get('user').then((val) => {
      if(val!=null){
        this.user = val;
      }
    });
  }

  ngOnInit() {
  }

  async select_filter_option(searchoption) {
    console.log("searchoption: "+searchoption);
    this.dismissPopover(searchoption);
  }

  async dismissPopover(searchoption) {
    await this.popoverController.dismiss({filter_type: searchoption});
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  async showLoader(msg){
    this.loading = await this.loadingCtrl.create({message: msg});
    this.loading.present();
  }

}
