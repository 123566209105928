import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PopoverSearchoptionsPageRoutingModule } from './popover-searchoptions-routing.module';

import { PopoverSearchoptionsPage } from './popover-searchoptions.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PopoverSearchoptionsPageRoutingModule
  ],
  declarations: [PopoverSearchoptionsPage]
})
export class PopoverSearchoptionsPageModule {}
