import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertController, LoadingController, NavController, MenuController, ToastController, Platform } from '@ionic/angular';

import * as moment from 'moment';

@Component({
  selector: 'app-reminder-modal',
  templateUrl: './reminder-modal.page.html',
  styleUrls: ['./reminder-modal.page.scss'],
})
export class ReminderModalPage implements OnInit {

  is_repeat: boolean = true;
  checkedRadioValue: any = 'every_day';
  cutomField: any = '';
  endTimeField: any = '';
  day_array = new Array(31);
  week_array = new Array(4);
  month_array = new Array(12);
  year_array = new Array(10);
  currentDate: any;
  futureDate: any;
  minT: any;
  maxT: any;
  
  start_date: any;
  start_time: any;
  end_date: any;
  end_time:any;

  custom_day: number = 0;
  custom_week: number = 0;
  custom_month: number = 0;
  custom_year: number = 0;
  occurence: number = 1;


  constructor(
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public menu: MenuController,
    public toastController: ToastController,
  ) {
    this.currentDate = moment().format('YYYY-MM-DD');
    this.futureDate = moment(this.currentDate).add(10, 'y').format('YYYY-MM-DD');

    this.minT = moment().format('YYYY-MM-DD');
    this.maxT = moment(this.currentDate).add(10, 'y').format('YYYY-MM-DD');

  }

  ngOnInit() {
  }
  async closeModal(action) {
    await this.modalController.dismiss({ action: action });
  }

  repeat_check(ev: any) {
    const isChecked = ev.currentTarget.checked;
    if (isChecked) {
      this.is_repeat = false;
    } else {
      this.is_repeat = true;
    }
    console.log("is_repeat: ", this.is_repeat);
  }

  onChangeHandler(event) {
    console.log("event.target.value", event.target.value);
    this.checkedRadioValue = event.target.value;

  }

  selectCustomField(event) {
    this.cutomField = event.detail.value;
    console.log("cutomField :", this.cutomField);
  }

  selectEndTimeField(ev: any) {
    this.endTimeField = ev.detail.value;
    console.log("endTimeField :", this.endTimeField);
  }
  selectCustomDay(ev: any) {
    this.custom_day = ev.detail.value;
  }
  selectCustomWeek(ev: any) {
    this.custom_week = ev.detail.value;
  }
  selectCustomMonth(ev: any) {
    this.custom_month = ev.detail.value;
  }
  selectCustomYear(ev: any) {
    this.custom_year = ev.detail.value;
  }


  addTime() {

    if (this.checkedRadioValue == 'custom' && this.cutomField == '') {
      this.presentToast("Please select custom day");
      return false;
    }
    if (this.start_date == undefined || this.start_date == '') {
      this.presentToast("Please select start date");
      return false;
    }
    if (this.start_time == undefined || this.start_time == '') {
      this.presentToast("Please select start time");
      return false;
    }
    if (this.is_repeat == true) {
      if (this.endTimeField == '') {
        this.presentToast("Please select end date");
        return false;
      }
      if (this.endTimeField == 'occurence' && this.occurence <= 0) {
        this.presentToast("Please enter occurrences");
        return false;
      }
      if (this.endTimeField == 'select_date' && (this.end_date == undefined || this.end_date == '')) {
        this.presentToast("Please select end date");
        return false;
      }
      if (this.endTimeField == 'select_date' && (this.end_time == undefined || this.end_time == '')) {
        this.presentToast("Please select end time");
        return false;
      }
    }

    let custom_field_value: any;

    if (this.cutomField == 'Day') {
      custom_field_value = this.custom_day;
    } else if (this.cutomField == 'Week') {
      custom_field_value = this.custom_week;
    } else if (this.cutomField == 'Month') {
      custom_field_value = this.custom_month;
    } else if (this.cutomField == 'Year') {
      custom_field_value = this.custom_year;
    } else {
      custom_field_value = '';
    }

    let tempData = {
      is_repeat: this.is_repeat,
      reminder_day: this.checkedRadioValue,
      custom_field_lavel: this.cutomField,
      custom_field_value: custom_field_value,
      start_date: this.changeToDate(this.start_date),
      start_time: this.changeToTime(this.start_time),
      end_date_label: this.endTimeField,
      end_date: this.changeToDate(this.end_date),
      end_time: this.changeToTime(this.end_time),
      occurence: this.occurence,
    }
    console.log("tempData :", tempData);
    this.modalController.dismiss({ action: 'ok', reminder: tempData });
  }
  changeToDate(dt) {
    if (!dt || dt == '' || dt == 'Invalid date' || dt == undefined) {
      return '';
    }
    let chk = moment(dt, 'YYYY-MM-DD', true).isValid();
    if (chk) {
      console.log("already valid");
      return dt;
    } else {
      console.log('Before; ', dt);
      let t = moment(dt, "YYYY-MM-DDTHH:mm:ssZ").format('YYYY-MM-DD');
      console.log('After; ', t);
      return t;
    }
  }
  changeToTime(dt) {
    if (!dt || dt == '' || dt == 'Invalid date' || dt == undefined) {
      return '';
    }
    let chk = moment(dt, 'HH:mm:ss', true).isValid();
    if (chk) {
      console.log("already valid");
      return dt;
    } else {
      console.log('Before; ', dt);
      let t = moment(dt, "YYYY-MM-DDTHH:mm:ssZ").format('HH:mm:ss');
      console.log('After; ', t);
      return t;
    }
  }
  async presentToast(msg) {
    console.log("presssssssssentttt");
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }
}
