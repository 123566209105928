import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DemoonePageRoutingModule } from './demoone-routing.module';

import { DemoonePage } from './demoone.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DemoonePageRoutingModule
  ],
  declarations: [DemoonePage]
})
export class DemoonePageModule {}
